import styled from 'styled-components';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';

export const SendModal = styled(BaseModal)`
  .ant-modal-content {
    min-height: 30vh;
    padding-left:2rem;
    padding-right:2rem; 

    }
`;
